import { onlyNumber, removeCommas } from '@common/module/replaceNumber';
import CorporateTaxModel from '@model/CorporateTaxModel';
import CorporateTaxService from '@service/CorporateTaxService';

class NationalTaxSendFirstVm {
  load(businessId, year) {
    CorporateTaxService.taxInfoLoad(
      businessId,
      year,
      'NATIONAL_TAX_SEND_FIRST',
      CorporateTaxModel.process.instalmentYn,
    );
  }
  get taxInfo() {
    return CorporateTaxModel.taxInfo;
  }
  get instalmentYn() {
    return CorporateTaxModel.process.instalmentYn;
  }
  async extendedDueDate(e, extendedDueDateKey, dueDateForPaymentKey) {
    const dueDateForPayment = CorporateTaxModel.taxInfo.nationalTax[dueDateForPaymentKey];

    // 국세 납부기한 1달 연장
    if (e.target.checked && dueDateForPayment) {
      //dueDateForPayment로 api요청
      const dueDate = await CorporateTaxService.getDueDate(dueDateForPayment);

      CorporateTaxModel.taxInfo.nationalTax[extendedDueDateKey] = dueDate;
    } else {
      CorporateTaxModel.taxInfo.nationalTax[extendedDueDateKey] = '';
    }
  }
  changeNationalTax_1st(files, deleteYn, editYn) {
    CorporateTaxModel.taxInfo.nationalTax_1st.files = files;
    if (deleteYn) {
      CorporateTaxModel.taxInfo.nationalTax_1st.deleteYn = true;
    }
    if (editYn) {
      CorporateTaxModel.taxInfo.nationalTax_1st.editYn = true;
    }
  }
  changeNationalTax_2nd(files, deleteYn, editYn) {
    CorporateTaxModel.taxInfo.nationalTax_2nd.files = files;
    if (deleteYn) {
      CorporateTaxModel.taxInfo.nationalTax_2nd.deleteYn = true;
    }
    if (editYn) {
      CorporateTaxModel.taxInfo.nationalTax_2nd.editYn = true;
    }
  }
  changeStatementOfTaxAdjustment(files, deleteYn, editYn) {
    CorporateTaxModel.taxInfo.statementOfTaxAdjustment.files = files;
    if (deleteYn) {
      CorporateTaxModel.taxInfo.statementOfTaxAdjustment.deleteYn = true;
    }
    if (editYn) {
      CorporateTaxModel.taxInfo.statementOfTaxAdjustment.editYn = true;
    }
  }
  changeFinancialStatementsConfirmation(file) {
    CorporateTaxModel.taxInfo.financialStatementsConfirmation = file;
  }
  changeEtcFile(files, deleteYn, editYn) {
    CorporateTaxModel.taxInfo.etcFile.files = files;
    if (deleteYn) {
      CorporateTaxModel.taxInfo.etcFile.deleteYn = true;
    }
    if (editYn) {
      CorporateTaxModel.taxInfo.etcFile.editYn = true;
    }
  }
  finalTaxLiability(e) {
    CorporateTaxModel.taxInfo.nationalTax.finalTaxLiability = onlyNumber(removeCommas(e.target.value));
  }
  prePaidTax(e) {
    CorporateTaxModel.taxInfo.nationalTax.prePaidTax = onlyNumber(removeCommas(e.target.value));
  }
  additionalTaxPayable(e) {
    CorporateTaxModel.taxInfo.nationalTax.additionalTaxPayable = onlyNumber(removeCommas(e.target.value));
  }
  specialTaxForRuralDevelopment(e) {
    CorporateTaxModel.taxInfo.nationalTax.specialTaxForRuralDevelopment = onlyNumber(removeCommas(e.target.value));
  }
  get total() {
    const finalTaxLiability = parseInt(this.taxInfo.nationalTax.finalTaxLiability) || 0;
    const prePaidTax = parseInt(this.taxInfo.nationalTax.prePaidTax) || 0;
    const additionalTaxPayable = parseInt(this.taxInfo.nationalTax.additionalTaxPayable) || 0;
    const specialTaxForRuralDevelopment = parseInt(this.taxInfo.nationalTax.specialTaxForRuralDevelopment) || 0;

    const _return = finalTaxLiability - prePaidTax + additionalTaxPayable + specialTaxForRuralDevelopment;

    return _return;
  }
  taxPayable_1st(e) {
    CorporateTaxModel.taxInfo.nationalTax.taxPayable_1st = onlyNumber(removeCommas(e.target.value));
  }
  dueDateForPayment_1st(e) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    CorporateTaxModel.taxInfo.nationalTax.dueDateForPayment_1st = date;
  }
  taxPayable_2nd(e) {
    CorporateTaxModel.taxInfo.nationalTax.taxPayable_2nd = onlyNumber(removeCommas(e.target.value));
  }
  dueDateForPayment_2nd(e) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    CorporateTaxModel.taxInfo.nationalTax.dueDateForPayment_2nd = date;
  }
  nationalTax_alimTalkToList(e, index) {
    CorporateTaxModel.taxInfo.nationalTax.alimTalkToList[index].checkedYn = e.target.checked;
  }
  changeLocalTaxFile(files, deleteYn, editYn) {
    CorporateTaxModel.taxInfo.localTaxFile.files = files;
    if (deleteYn) {
      CorporateTaxModel.taxInfo.localTaxFile.deleteYn = true;
    }
    if (editYn) {
      CorporateTaxModel.taxInfo.localTaxFile.editYn = true;
    }
  }
  taxAmount(e) {
    CorporateTaxModel.taxInfo.localTax.taxAmount = onlyNumber(removeCommas(e.target.value));
  }
  localTaxDueDateForPayment(e) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    CorporateTaxModel.taxInfo.localTax.dueDateForPayment = date;
  }
  localTax_alimTalkToList(e, index) {
    CorporateTaxModel.taxInfo.localTax.alimTalkToList[index].checkedYn = e.target.checked;
  }
  get activeNationalTaxCompletedNotice() {
    let active = true;

    if (this.instalmentYn === true) {
      if (!this.taxInfo.nationalTax_1st.files || this.taxInfo.nationalTax_1st.files.length === 0) {
        active = false;
      }
      if (!this.taxInfo.nationalTax_2nd.files || this.taxInfo.nationalTax_2nd.files.length === 0) {
        active = false;
      }
      if (!this.taxInfo.nationalTax.taxPayable_1st && this.taxInfo.nationalTax.taxPayable_1st !== 0) {
        active = false;
      }
      if (!this.taxInfo.nationalTax.dueDateForPayment_1st) {
        active = false;
      }
      if (!this.taxInfo.nationalTax.taxPayable_2nd && this.taxInfo.nationalTax.taxPayable_2nd !== 0) {
        active = false;
      }
      if (!this.taxInfo.nationalTax.dueDateForPayment_2nd) {
        active = false;
      }
    } else {
      if (!this.taxInfo.nationalTax.dueDateForPayment) {
        active = false;
      }
    }
    if (!this.taxInfo.statementOfTaxAdjustment.files || this.taxInfo.statementOfTaxAdjustment.files.length === 0) {
      active = false;
    }
    if (!this.taxInfo.nationalTax.finalTaxLiability && this.taxInfo.nationalTax.finalTaxLiability !== 0) {
      active = false;
    }

    const checkedYn = this.taxInfo.nationalTax.alimTalkToList.some((item) => {
      //알림톡 받으실분
      return item.checkedYn === true;
    });

    if (checkedYn === false) {
      active = false;
    }

    return active;
  }
  get activeLocalTaxCompletedNotice() {
    let active = true;

    if (!this.taxInfo.localTax.taxAmount && this.taxInfo.localTax.taxAmount !== 0) {
      active = false;
    }
    if (!this.taxInfo.localTax.dueDateForPayment && this.taxInfo.localTax.dueDateForPayment !== 0) {
      active = false;
    }

    const checkedYn = this.taxInfo.localTax.alimTalkToList.some((item) => {
      //알림톡 받으실분
      return item.checkedYn === true;
    });

    if (checkedYn === false) {
      active = false;
    }

    return active;
  }
  changeNationalTaxFile(files, deleteYn, editYn) {
    CorporateTaxModel.taxInfo.nationalTaxFile.files = files;
    if (deleteYn) {
      CorporateTaxModel.taxInfo.nationalTaxFile.deleteYn = true;
    }
    if (editYn) {
      CorporateTaxModel.taxInfo.nationalTaxFile.editYn = true;
    }
  }
  nationalTaxDueDateForPayment(e) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    CorporateTaxModel.taxInfo.nationalTax.dueDateForPayment = date;
  }
  get nationalTaxCompletedNoticeParam() {
    const taxInfo = CorporateTaxModel.taxInfo;
    const process = CorporateTaxModel.process;
    const param = {
      corporateTaxId: process.corporateTaxId,
      instalmentYn: process.instalmentYn,
      statementOfTaxAdjustment: {
        deleteYn: taxInfo.statementOfTaxAdjustment?.deleteYn ? true : false,
        files: taxInfo.statementOfTaxAdjustment?.editYn ? taxInfo.statementOfTaxAdjustment?.files : [],
      },
      financialStatementsConfirmation: {
        id: taxInfo.financialStatementsConfirmation?.id || null,
      },
      etcFile: {
        deleteYn: taxInfo.etcFile?.deleteYn ? true : false,
        files: taxInfo.etcFile?.editYn ? taxInfo.etcFile?.files : [],
      },
      nationalTax: taxInfo.nationalTax,
      alimTalkTargets: taxInfo.nationalTax.alimTalkToList
        .filter((person) => {
          return person.checkedYn === true;
        })
        .map((checkedPerson) => {
          return checkedPerson.id;
        }),
    };

    if (!taxInfo.financialStatementsConfirmation?.id && taxInfo.financialStatementsConfirmation) {
      param.financialStatementsConfirmation.file = taxInfo.financialStatementsConfirmation;
    }
    if (process.instalmentYn) {
      param.nationalTax_1st = {
        deleteYn: taxInfo.nationalTax_1st?.deleteYn ? true : false,
        files: taxInfo.nationalTax_1st?.editYn ? taxInfo.nationalTax_1st?.files : [],
      };
      param.nationalTax_2nd = {
        deleteYn: taxInfo.nationalTax_2nd?.deleteYn ? true : false,
        files: taxInfo.nationalTax_2nd?.editYn ? taxInfo.nationalTax_2nd?.files : [],
      };
    } else {
      param.nationalTaxFile = {
        deleteYn: taxInfo.nationalTaxFile?.deleteYn ? true : false,
        files: taxInfo.nationalTaxFile?.editYn ? taxInfo.nationalTaxFile?.files : [],
      };
    }

    return param;
  }
  get localTaxCompletedNoticeParam() {
    const taxInfo = CorporateTaxModel.taxInfo;
    const process = CorporateTaxModel.process;
    const param = {
      corporateTaxId: process.corporateTaxId,
      localTaxFile: {
        deleteYn: taxInfo.localTaxFile?.deleteYn ? true : false,
        files: taxInfo.localTaxFile?.editYn ? taxInfo.localTaxFile?.files : [],
      },
      instalmentYn: process.instalmentYn,
      localTax: taxInfo.localTax,
      alimTalkTargets: taxInfo.localTax.alimTalkToList
        .filter((person) => {
          return person.checkedYn === true;
        })
        .map((checkedPerson) => {
          return checkedPerson.id;
        }),
    };

    return param;
  }
  async sendNationalTaxCompletedNotice(businessId) {
    await CorporateTaxService.sendNationalTaxCompletedNotice(this.nationalTaxCompletedNoticeParam, businessId);
  }
  async saveaNationalTaxCompletedNotice(businessId) {
    await CorporateTaxService.saveaNationalTaxCompletedNotice(this.nationalTaxCompletedNoticeParam, businessId);
  }
  async sendLocalTaxCompletedNotice(businessId) {
    await CorporateTaxService.sendLocalTaxCompletedNotice(this.localTaxCompletedNoticeParam, businessId);
  }
  async saveLocalTaxCompletedNotice(businessId) {
    await CorporateTaxService.saveLocalTaxCompletedNotice(this.localTaxCompletedNoticeParam, businessId);
  }
}

export default new NationalTaxSendFirstVm();
